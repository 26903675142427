// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #181e2c;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
}
.MuiListItemButton-root.Mui-selected{
    color: #000 !important;
    background-color: #8ff7d5 !important;
    .MuiListItemIcon-root{
        color:#000 !important
    }
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

@media only screen and (max-width: 800px) {

    .lastTableRow{
        display: none !important;
    }
    
    /* Force table to not be like tables anymore */
	#no-more-tables table, 
	#no-more-tables thead, 
	#no-more-tables tbody, 
	#no-more-tables th, 
	#no-more-tables td, 
	#no-more-tables tr { 
		display: block; 
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	#no-more-tables thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#no-more-tables tr { border: 1px solid #ccc; margin-bottom: 20px;border-radius:5px;background:#ddd;margin-top: 10px;}
 
    .OrderDetailsCard{
        padding-left: 30% !important;
    }

	#no-more-tables td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #5cc5a3; 
		position: relative;
		padding-left: 48%; 
		white-space: normal;
		text-align:left;
		min-height: 25px;
		background:#8ff7d5;
	}
 
	#no-more-tables td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 32%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
	
	
	#no-more-tables td:first-child { 
		background:#8ff7d5;
	}
	
	#no-more-tables td:last-child { 
		border-bottom:0px;
		background:#8ff7d5;
	}
	
	#no-more-tables #NewempTable td:nth-child(4):before { 
		color:red;
	}
	
	#no-more-tables #NewempTable td:nth-child(5):before { 
		color:red;
	}
	
	#no-more-tables #empTable td:nth-child(5):before { 
		color:red;
	}
	
	#no-more-tables #empTable td:nth-child(6):before { 
		color:red;
	}
	
	#no-more-tables #unverified-empTable td:nth-child(5):before { 
		color:red;
	}
	
	#no-more-tables #unverified-empTable td:nth-child(6):before { 
		color:red;
	}
	
	#no-more-tables #completed-empTable td:nth-child(5):before { 
		color:red;
	}
	
	#no-more-tables #completed-empTable td:nth-child(6):before { 
		color:red;
	}
	
	#no-more-tables #trash-empTable td:nth-child(5):before { 
		color:red;
	}
	
	#no-more-tables #trash-empTable td:nth-child(6):before { 
		color:red;
	}
	
	
	#no-more-tables .completed-table td:nth-child(4):before { 
		color:red;
	}
	
	#no-more-tables .completed-table td:nth-child(6):before { 
		color:#212529 !important;
	}
	
 
	/*
	Label the data
	*/
	#no-more-tables td:before { content: attr(data-title); }
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }

// ==============================|| PERFECT SCROLLBAR ||============================== //
.MuiLoadingButton-root{
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}
.css-iysw8l div:first-child{
    width: 100%;
}
.MuiLoadingButton-loadingIndicator{
    width: auto !important;
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
.css-fcpx8l-MuiFormHelperText-root{
    margin-left:0 !important;
}
.bottom-nav-class{
    overflow-y: scroll;
    height: auto !important ;
    justify-content: unset !important;
    .MuiBottomNavigationAction-label{
        font-size:0.75rem;
        margin-top: 4px;
    }
    .MuiButtonBase-root{
        min-width: 73px !important;
        max-width: 73px !important;
    }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
.bottom-nav-class::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .bottom-nav-class {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .MuiMenu-list{
    .MuiMenuItem-gutters{
        min-height: 33px !important;
    }
  }
  .phoneInput{
    .MuiOutlinedInput-root{
        height: 62px !important;
    }
  }
  .MuiTableRow-root:nth-of-type(odd){
    background-color: #8ff7d5 !important;
  }
  .MuiTableRow-root{
    background-color: #8ff7d5 !important;
  }
  .MuiTableCell-root{
    border-bottom: 1px solid rgb(179 177 177);
  }
 